// import "./styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Slider() {
  const images = [
    {
      key:101,
      id: "102",
      author: "Ben Moore",
      width: 1200,
      height: 400,
      url: "./Content/images/workers-textile.png",
      download_url: "./Content/images/workers-textile.png",
      text:"The textile sourcing and inspection service"
    },
    {
      key:102,
      id: "103",
      author: "Ilham Rahmansyah",
      width: 1000,
      height: "400px",
      url: "./Content/images/yarn.png",
      download_url: "./Content/images/yarn.png",
      text:"The textile sourcing and inspection service"
    },
    {
      key:103,
      id: "104",
      author: "Dyaa Eldin",
      width: 1200,
      height: 400,
      url: "./Content/images/TA1.jpg",
      download_url: "./Content/images/TA1.jpg",
      text:"We only source the highest quality Textile products"
    },
    {
      key:104,
      id: "106",
      author: "Arvee Marie",
      width: 1200,
      height: 400,
      url: "./Content/images/Textile1.jpg",
      download_url: "./Content/images/Textile1.jpg",
      text:"Our quality department choose best quality"
    },
    {
      key:105,
      id: "107",
      author: "Lukas Schweizer",
      width: 1200,
      height: 400,
      url: "./Content/images/TA4.jpg",
      download_url: "./Content/images/TA4.jpg",
      text:"The textile sourcing and inspection service"
    },
    {
      key:106,
      id: "108",
      author: "Florian Klauer",
      width: 1200,
      height: 400,
      url: "./Content/images/TA6.jpg",
      download_url: "./Content/images/TA6.jpg",
      text:"We provide products in best prices always"
    },
    {
      key:107,
      id: "109",
      author: "Zwaddi",
      width: 1200,
      height: 400,
      url: "./Content/images/TA7.jpg",
      download_url: "./Content/images/TA7.jpg",
      text:"We care our existing customers and maintain there records"
    }
  ];
  return (
    <>
    <section className="slider_section ">
    <div id="customCarousel21" className="carousel slide" data-ride="carousel">
      <Carousel
        className="crsl"
        autoPlay
        infiniteLoop
        centerMode
        interval={1000}
        autoplayHoverPause={false}
      >
        {images.map((image) => (
          <>
          <img src={image.download_url} id={image.id} alt={image.author}/> 
          <span className="legend"><h3>{image.text}</h3></span>
          </>
        ))}
       
      </Carousel>
      </div>
      </section>
      </>
  );
}
