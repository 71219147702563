import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
      <>
        <section className="info_section ">
<div className="container">
  <div className="contact_nav">
    <Link to="/contactus">
      <i className="fa fa-phone" aria-hidden="true"></i>
      <span>
        Call : +923008652605
      </span>
    </Link>
    <Link to="/contactus">
      <i className="fa fa-phone" aria-hidden="true"></i>
      <span>
        Phone : +923331612006
      </span>
    </Link>
   
    <Link to="/contactus">
      <i className="fa fa-envelope" aria-hidden="true"></i>
      <span>
        Email : info@textilearray.com
      </span>
    </Link>
    <Link to="/contactus">
      <i className="fa fa-map-marker" aria-hidden="true"></i>
      <span>
        Location
      </span>
    </Link>
  </div>

  <div className="info_top ">
    <div className="row info_main_row">
      <div className="col-sm-6 col-md-4 col-lg-3">
        <div className="info_links">
          <h4>
            QUICK LINKS
          </h4>
          <div className="info_links_menu">
            <Link className="" to="/home">Home <span className="sr-only">(current)</span></Link>
            <Link className="" to="/services">Services</Link>
            <Link className="" to="/about"> About</Link>
            <Link className="" to="/qualitycontrol">Quality Control</Link>
            <Link className="" to="/tesimonial">Testimonial</Link>
            <Link className="" to="/contactus">Contact Us</Link>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-4 col-lg-3 mx-auto">
        <div className="info_post">
          <h5>
            INSTAGRAM FEEDS
          </h5>
          <div className="post_box">
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
            <div className="img-box">
              <img src="./Content/images/instagram.jpg" alt=""/>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <div className="info_bottom">
    <div className="row">
      <div className="col-md-2">
        <div className="info_logo">
          <Link to="/home">
            <img src="./Content/images/textileArray-logo.png" alt=""/>
          </Link>
        </div>
      </div>
      <div className="col-md-4 ml-auto">
        <div className="social_box">
          <a href="">
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a href="">
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </a>
          <a href="">
            <i className="fa fa-linkedin" aria-hidden="true"></i>
          </a>
          <a href="">
            <i className="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>

  </div>
</div>
</section>
      </>
    )
  }

