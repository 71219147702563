import React from 'react'
import About from './About'
import Services from './Services'
import QualityControl from './QualityControl'
import Testimonial from './Testimonial'
import ContactUs from './ContactUs'
import Slider from './Slider';
import Navbar from './Navbar'
export default function Home () {
    return (
      <>
        <Slider/>
        <About/>
        <Services/>
        <QualityControl/>
        <Testimonial/>
        <ContactUs/>
      </>
    )
  }

