import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function Navbar() {
    return (
      <>
    <header className="header_section">
      <div className="header_top">
        <div className="container-fluid header_top_container">
          <div className="lang_box dropdown">
           
          </div>
          <div className="contact_nav">
            <Link to="/contactus">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <span>
                Call : +923008652605
              </span>
            </Link>
            <Link to="/contactus">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <span>
                Phone : +923331612006
              </span>
            </Link>
            <Link to="/contactus">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <span>
                Email : info@textilearray.com
              </span>
            </Link>
            <Link to="/contactus">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              <span>
                Location
              </span>
            </Link>
          </div>
          <div className="social_box">
            <a href="">
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
            <a href="">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="header_bottom">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg custom_nav-container ">
            <Link  className="navbar-brand" to="/home">
              <img src="./Content/images/textileArray-logo.png" alt=""/>
            </Link>
           
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className=""> </span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav  ">
                <li className="nav-item active-home">
                  <Link className="nav-link" to="/home">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item active-about">
                  <Link className="nav-link" to="/about"> About</Link>
                </li>
                <li className="nav-item active-Services">
                  <Link className="nav-link" to="/services">Services</Link>
                </li>                
                <li className="nav-item active-QC">
                  <Link className="nav-link" to="/qualitycontrol">Quality Control</Link>
                </li>
                <li className="nav-item active-Testimonial">
                  <Link className="nav-link" to="testimonial">Testimonial</Link>
                </li>
                <li className="nav-item active-ContactUs"> 
                  <Link className="nav-link" to="contactus">Contact Us</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    
</>
)};
