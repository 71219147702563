import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function About () {
    return (
      <>
        <section className="about_section layout_padding">
    <div className="container  ">
      <div className="row">
        <div className="col-md-6">
          <div className="detail-box">
            <div className="heading_container">
              <h2>
                About Us Textile Array
              </h2>
            </div>
            <p>
              Textile Array is the leading textile sourcing and quality control agency in Pakistan that enables its buyers to make quick decisions effectively, with less time. We ensure that the products you import are sourced from reliable companies and are of excellent quality. As with many years’ experience Textile Array does not compromise on quality.
            </p>
            <div className="heading_container">
                <h3>Pakistan Textile Industry Facts</h3>
                    </div>
                            <p>Pakistan is 5th largest cotton growing country in the world.
                            The largest exporter of cotton yarn.
                            3rd largest raw cotton exporter in the world.
                            4th largest consumer of cotton.
                            As a whole 10 to 15% of Pakistan’s cotton is consumed domestically; remaining is exported as raw cotton, yarn, fabric and made ups
               </p>
               <Link className="nav-link" to="/about"> 
              Read More
              </Link>
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="img-box">
            <img src="./Content/about-img.jpg" alt=""/>
          </div>
        </div>
      </div>
    </div>
  </section>
      </>
    )
  }

