import React from 'react'

export default function Testimonial () {
   return (
      <>
        <section className="client_section layout_padding">
    <div className="container">
      <div className="heading_container heading_center">
        <h2>
          Testimonial
        </h2>
      </div>
    </div>
    <div id="customCarousel2" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="box">
                  <div className="img-box">
                    <img src="images/client.jpg" alt=""/>
                  </div>
                  <div className="detail-box">
                    <div className="client_info">
                      <div className="client_name">
                        <h5>
                          John
                        </h5>
                        <h6>
                          Customer
                        </h6>
                      </div>
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                    </div>
                    <p>
                    I was given a warmed welcome and their fabrics was so beautiful n nice that I don't know which to go for, they are wonderful people with good manners ready to helped you selected on what to get for the occasion you want to use the fabric for infant I love to be there again I love their customer service is 100%.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="box">
                  <div className="img-box">
                    <img src="images/client.jpg" alt=""/>
                  </div>
                  <div className="detail-box">
                    <div className="client_info">
                      <div className="client_name">
                        <h5>
                          Kathline
                        </h5>
                        <h6>
                          Customer
                        </h6>
                      </div>
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                    </div>
                    <p>
                    I found this amazing supplier and could not wait to visit. Having spoken to Dom on the phone and heard his confidence in finding the fabric , I became very excited in the lead up to the visit. I was not disappointed! Dom had instructed me to take one of the wedding day shoes with me ( I had already had an outift made pre lockdown but had gone off the boil with the finished result) as a solid starting point. The shop is just unreal. A million or so metres of fabric. Dom helped me all the way to match a beautiful pure silk with an overlay of French silver lace that looks stunning with the shoes and bag. Not cheap, but worth every penny! Thanks Textile Array!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="box">
                  <div className="img-box">
                    <img src="images/client.jpg" alt=""/>
                  </div>
                  <div className="detail-box">
                    <div className="client_info">
                      <div className="client_name">
                        <h5>
                          Adam Leo
                        </h5>
                        <h6>
                          Customer
                        </h6>
                      </div>
                      <i className="fa fa-quote-left" aria-hidden="true"></i>
                    </div>
                    <p>
                    I have been a client of Textile Array for some forty years (time does fly!) and feel like an old personal friend. I greatly appreciate their fabulous selection of luxurious, timelessly elegant fabrics. Now that I don’t travel to London that often I find their online shop is excellent, I was hesitant of placing an order for delivery from the UK to the EU but, by some sort of magic, my parcel arrived the day after I placed my order. Joel and Son are really the world’s best fabric shop and I thank them, not only for providing me with so many beautiful fabrics, but also for years of kindness and friendship
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ol className="carousel-indicators">
        <li data-target="#customCarousel2" data-slide-to="0" className="active"></li>
        <li data-target="#customCarousel2" data-slide-to="1"></li>
        <li data-target="#customCarousel2" data-slide-to="2"></li>
      </ol>
    </div>
  </section>
      </>
    )
  }

