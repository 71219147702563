import React from 'react'
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function ContactUs () {
  const defaultProps = {
    center: {
      lat: 31.504544,
      lng:  73.214992
    },
    zoom: 18
  };
   return (
      <>
        <section className="contact_section layout_padding layout_padding-bottom">
    <div className="container-fluid">
      <div className="col-lg-4 col-md-5 offset-md-1">
        <div className="heading_container">
          <h2>
            Contact Us
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-5 offset-md-1">
          <div className="form_container">
            <form action="">
              <div>
                <input type="text" placeholder="Your Name" />
              </div>
              <div>
                <input type="text" placeholder="Phone Number" />
              </div>
              <div>
                <input type="email" placeholder="Email" />
              </div>
              <div>
                <input type="text" className="message-box" placeholder="Message" />
              </div>
              <div className="btn_box">
                <button>
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-lg-7 col-md-6 px-0">
          <div className="map_container">
            <div className="map">
            <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
           lat={31.502450}
           lng={73.213000}
          text="Textile Array"
        />
      </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
      </>
    )
  }

